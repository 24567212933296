/* eslint-disable */
import epppr2020 from '../data/eppprData';
import epppr2021 from '../data/eppprData-2021';
import epppr2022 from '../data/eppprData-2022';

import Cookie from 'js-cookie';

function getEpppr(year) {
  if (year === 2022 || year === '2022') {
    return epppr2022;
  }
  if (year === 2021 || year === '2021') {
    return epppr2021;
  }
  return epppr2020;
}
function getToken() {
  if (process.env.VUE_APP_USE_AUTHENTICATION === 'NO') {
    return 'AUTH-IS-OFF-IN-CONFIG';
  }
  return Cookie.get('token');
}
const getters = {
  auth(state) {
    const token = getToken();
    if (token && token.length && token.length > 0) {
      return true;
    }
    return false;
  },
  map: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const m = epppr.Map.filter((line) => line.ProviderName === provider && line.ReportYear == year)[0];
    return { FileName: m.FileName };
  },
  year(state) {
    // const epppr = epppr2020;
    // return epppr.Year.sort((a,b) => b.Year-a.Year)
    return [{ Year: 2022 }, { Year: 2021 }, { Year: 2020 }, { Year: 2019 }, { Year: 2018 }, { Year: 2017 }, { Year: 2016 }, { Year: 2015 }, { Year: 2014 }];
  },
  provider: (state) => (year) => {
    const epppr = getEpppr(year);
    return epppr.SelectionCriteria.filter((line) => line.ReportYear === year).sort((a, b) => {
      let pa = a.ProviderName;
      let pb = b.ProviderName;
      if (pa.substring(0, 2) === 'NJ') {
        pa = ` ${pa}`;
      }
      if (pb.substring(0, 2) === 'NJ') {
        pb = ` ${pb}`;
      }
      return (pa === pb ? 0 : (pa < pb ? -1 : 1));
    });
  },
  programs: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const h = epppr.SelectionCriteria.filter((line) => line.ProviderName === provider && line.ReportYear == year);
    return h.sort((a, b) => a.ProgramName.localeCompare(b.ProgramName));
  },
  header: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.ReportHeader.filter((line) => (line.ProviderName === provider) && (line.ReportYear == year) && (line.ProgramName === program || program === 'All Programs'))[0];
    if (program === 'All Programs') {
      h.ProgramName = '';
    }
    return { ProviderName: h.ProviderName, ProgramName: h.ProgramName, ProviderCourseWorkLevel: h.ProviderCourseWorkLevel };
  },
  mission: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const m = epppr.ProviderProfile.filter((line) => line.ProviderName === provider && line.ReportYear == year)[0];
    return { Mission: m.Mission, NumberOfPartnerships: m.NumberOfPartnerships };
  },
  context: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const m = epppr.ProviderProfile.filter((line) => line.ProviderName === provider && line.ReportYear == year)[0];
    return { InstitutionalContext: m.InstitutionalContext };
  },
  profile: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const m = epppr.ProviderProfile.filter((line) => line.ProviderName === provider && line.ReportYear == year)[0];
    return { Address: m.Address, Website: m.Website, FinancialAidOptions: m.FinancialAidOptions };
  },
  completer: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.CompleterPopulation.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program)[0];
    return { TotalCount: h.TotalCount };
  },
  certificate: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.CompleterPopulation.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program)[0];
    return { MultipleEndorsementCount: h.MultipleEndorsementCount };
  },
  totalCertificate: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.CompleterPopulation.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program)[0];
    return { TotalCertificateCount: h.TotalCertificationCount };
  },
  gender: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.CompleterPopulationByGender.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },
  race: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.CompleterPopulationByRace.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    const label = {
      White: 0, /* 'Black': 0, */ Hispanic: 0, /* 'Asian / Pacific Islander': 0, */ 'Native American': 0, 'Race Not Reported': 0, Other: 0,
    };
    const data = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Subgroup] = l.Percentage;
    });
    for (const key in label) {
      labels.push(key);
      data.push(label[key]);
    }
    return { labels, data };
  },
  p12: (state) => (year) => {
    const epppr = getEpppr(year);
    const h = epppr.StateK12.filter((line) => line.ReportYear == year);
    const label = {
      White: 0, /* 'Black': 0, */ Hispanic: 0, /* 'Asian / Pacific Islander': 0, */ 'Native American': 0, 'Race Not Reported': 0, Other: 0,
    };
    const data = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Subgroup] = l.Percentage;
    });
    for (const key in label) {
      if (key === 'Total') {
      } else {
        labels.push(key);
        data.push(label[key]);
      }
    }
    return { labels, data };
  },
  workforce: (state) => (year) => {
    const epppr = getEpppr(year);
    const h = epppr.StateTeacherWorkforce.filter((line) => line.ReportYear == year);
    const label = {
      White: 0, /* 'Black': 0, */ Hispanic: 0, /* 'Asian / Pacific Islander': 0, */ 'Native American': 0, 'Race Not Reported': 0, Other: 0,
    };
    const data = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Subgroup] = l.Percentage;
    });
    for (const key in label) {
      if (key === 'Total') {
      } else {
        labels.push(key);
        data.push(label[key]);
      }
    }
    return { labels, data };
  },
  prxcore: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.AssessmentsCore.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    /**
     * Function to sort alphabetically an array of objects by some specific key.
     *
     * @param {String} property Key of the object to sort.
     */
    function dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
      };
    }
    return h.sort(dynamicSort('TestName'));
  },
  praxis: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.AssessmentsPraxis.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    /**
     * Function to sort alphabetically an array of objects by some specific key.
     *
     * @param {String} property Key of the object to sort.
     */
    function dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
      };
    }
    return h.sort(dynamicSort('TestName'));
  },
  trends: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentPersistence.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },

  areas: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentCertArea.filter((line) => line.ProviderName === provider && line.ReportYear == year);
    /**
       * Function to sort alphabetically an array of objects by some specific
       * key.
       *
       * @param {String} property Key of the object to sort.
       */
    function dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
      };
    }
    return h.sort(dynamicSort('Certification'));
  },
  counts: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentEndorsementCount.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },
  regions: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentRegion.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },
  classifications: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentSchoolClassification.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },
  categories: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentSchoolCategory.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    return h;
  },
  providerData: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EmploymentLargeProgProvider.filter((line) => line.ProviderName === provider && line.ReportYear == year);
    const data = [];
    const labels = [];
    const label = [];
    h.forEach((l) => {
      label[l.ProgramName] = l.Total;
    });
    for (const key in label) {
      labels.push(key);
      data.push(label[key]);
    }
    return { labels, data };
  },
  providerStateData: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    let providerName = '';
    if (provider.indexOf(' CEAS') >= 0 && year > 2018) {
      providerName = 'NJ CEAS Providers';
    } else if (provider.indexOf(' CE') >= 0 && year > 2018) {
      providerName = 'NJ CE Providers';
    } else {
      providerName = 'ALL';
    }
    const h = epppr.EmploymentLargeProgState.filter((line) => line.ProviderName === providerName && line.ReportYear == year);
    const data = [];
    const labels = [];
    const label = [];
    h.forEach((l) => {
      label[l.ProgramName] = l.Total;
    });
    for (const key in label) {
      labels.push(key);
      data.push(label[key]);
    }
    return { labels, data };
  },
  financialAid: (state) => (provider, year) => {
    const epppr = getEpppr(year);
    const h = epppr.ProviderProfile.filter((line) => line.ProviderName === provider && line.ReportYear == year)[0];
    return h.FinancialAidAvailable.split(/,/);
  },
  sgoScore: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EvaluationTeacherSGO.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    const label = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const label2 = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const count = [];
    const percent = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Rating] = l.TotalCount;
      label2[l.Rating] = l.Percentage;
    });
    for (const key in label) {
      labels.push(key);
      count.push(label[key]);
      percent.push(label2[key]);
    }
    return { labels, count, percent };
  },
  sgpScore: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EvaluationTeacherSGP.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    const label = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const label2 = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const count = [];
    const percent = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Rating] = l.TotalCount;
      label2[l.Rating] = l.Percentage;
    });
    for (const key in label) {
      labels.push(key);
      count.push(label[key]);
      percent.push(label2[key]);
    }
    return { labels, count, percent };
  },
  practiceScore: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EvaluationTeacherPractice.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    const label = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const label2 = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const count = [];
    const percent = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Rating] = l.TotalCount;
      label2[l.Rating] = l.Percentage;
    });
    for (const key in label) {
      labels.push(key);
      count.push(label[key]);
      percent.push(label2[key]);
    }
    return { labels, count, percent };
  },
  rating: (state) => (provider, program, year) => {
    const epppr = getEpppr(year);
    const h = epppr.EvaluationSummative.filter((line) => line.ProviderName === provider && line.ReportYear == year && line.ProgramName === program);
    const label = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const label2 = {
      'Highly Effective': 0, Effective: 0, 'Partially Effective': 0, Ineffective: 0, NE: 0,
    };
    const count = [];
    const percent = [];
    const labels = [];
    h.forEach((l) => {
      label[l.Rating] = l.TotalCount;
      label2[l.Rating] = l.Percentage;
    });
    for (const key in label) {
      labels.push(key);
      count.push(label[key]);
      percent.push(label2[key]);
    }
    return { labels, count, percent };
  },
  viewportSize: (state) => state.viewport,
};

export default getters;
