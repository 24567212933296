<template>
  <div>
      <!-- <main-epppr-header :provider="provider" :program="program"
        :year="year"></main-epppr-header> -->
      <page-title-color-bar :items="items"></page-title-color-bar>
      <v-layout row wrap style="margin-top: 17px;margin-left:4px">
      <!-- <c-a-prx-core :provider="provider"
       :program="program" :year="year" v-if="this.year>=2022"></c-a-prx-core> -->
      <c-a-content :provider="provider" :program="program" :year="year"></c-a-content>
      <c-a-performance v-if="this.year<2022"></c-a-performance>
      <c-a-basic :provider="provider" :program="program" :year="year"></c-a-basic>
    </v-layout >
    <footnotes :items="notes"></footnotes>
  </div>
</template>

<script>
import PageTitleColorBar from '../../components/PageTitleColorBar.vue';
import CABasic from '../../components/ca/CABasicComponent.vue';
import footnotes from '../../components/FootnotesComponent.vue';
// import MainEppprHeader from '../../../../components/MainEppprHeader';
import CAContent from '../../components/ca/CAContentComponent.vue';
import CAPerformance from '../../components/ca/CAPerformanceComponent.vue';
// import CAPrxCore from '../../components/ca/CAPrxCoreComponent.vue';

export default {
  components: {
    // CAPrxCore,
    CAPerformance,
    CAContent,
    // MainEppprHeader,
    footnotes,
    CABasic,
    PageTitleColorBar,
  },
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  computed: {
    glossaryLink() {
      return `/glossary/${this.$route.params.year}/${this.$route.params.provider}/${this.$route.params.program}`;
    },
    notes() {
      if (this.year >= 2022) {
        return [
          { id: 5, note: '<sup>5</sup> Praxis Core PRAXIS II is an assessment that meets the basic skills requirement for certification. This data is suppressed if the count of valid scores is less than 10.' },
          { id: 7, note: '<sup>7</sup> First-Time Pass Rate is the percentage of certified program completes who passed the assessment on their first attempt.' },
          { id: 8, note: '<sup>8</sup> Overall Pass Rate is the percentage of certified program completes who passed the assessment.' },
        ];
      }

      return [
        { id: 5, note: `<sup>5</sup> PRAXIS II is a content assessment required for certification. Future report iterations will include data on additional assessments required for certification and survey results from program completers and employers. For information about expired assessments, see the <a href="${this.glossaryLink}">glossary</a> at the end of this report. This data is suppressed if the count of valid scores is less than 10.` },
        { id: 6, note: '<sup>6</sup> edTPA is the Commissioner-approved assessment measuring a candidate’s ability to prepare a lesson, deliver instruction, and assess student learning.' },
      ];
    },
    certificationTitle() {
      if (this.year >= 2020) {
        return 'Certification Assessment Results';
      }
      return 'Certification Assessments';
    },
    items() {
      return [
        {
          icon: 'fas fa-chart-line ca',
          colorbar: 'ca-color-bar',
          color: 'ca title-size',
          title: this.certificationTitle,
          to: '/certification-assessments',
          subtitle: 'Data about program completer performance on required licensure assessments.',
        },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
    .v-card{
        box-shadow: none !important;
    }
</style>
<style>
  tbody tr:nth-child(odd) {
    background: #e0e0e0;
  }
  thead{
    background: #595959;
  }
  .table-header {
    color: #ffffff;
    font-size: 1.5rem;
  }
  .ca{
    color: #60521B;
  }
  .container {
    flex: 0;
    padding: 0;
  }
  .v-icon.fa-sort-up,
  .v-icon.fa-sort-down {
    font-size: 18px !important;
    color: #2C7FC3 !important;
  }
</style>
