<template>
  <div>
    <div class="pageHeader">
      <!-- <main-epppr-header :provider="provider"
        :program="program" :year="year"></main-epppr-header> -->
      <page-title-color-bar :items="items"></page-title-color-bar>
    </div>
      <div class="content-container">
        <v-container fluid>
          <v-row style="margin-left:12px; margin-top: 10px;">
              <v-col cols="auto">
                <e-r-key></e-r-key>
              </v-col>
          </v-row>
                <v-row style="margin-left:6px; margin-top: 10px;margin-right:6px;">
                  <v-col cols="3" class="" style="">
                    <e-r-summative-rating :provider="provider" :program="program"
                      :year="year"></e-r-summative-rating>
                  </v-col>
                  <v-col cols="3">
                    <e-r-teacher-practice-score :provider="provider" :program="program"
                    :year="year"></e-r-teacher-practice-score>
                  </v-col>
                  <v-col cols="3">
                    <e-r-teacher-s-p-g-score :provider="provider" :program="program"
                    :year="year"></e-r-teacher-s-p-g-score>
                  </v-col>
                  <v-col cols="3">
                    <e-r-teacher-s-g-o-score :provider="provider" :program="program" :year="year">
                    </e-r-teacher-s-g-o-score>
                  </v-col>
                </v-row>
        </v-container>
        <div v-if="showOverlay" class="overlay">
          <div class="overlay-message">
            <span class="overlay-message">{{overlayMessage}}</span>
          </div>
        </div>
      </div>
      <footnotes :items="notes"></footnotes>
  </div>
</template>

<script>

import ERTeacherSGOScore from '../../components/er/ERTeacherSGOScoreComponent.vue';
import ERTeacherSPGScore from '../../components/er/ERTeacherSPGScoreComponent.vue';
import ERTeacherPracticeScore from '../../components/er/ERTeacherPracticeScoreComponent.vue';
import ERSummativeRating from '../../components/er/ERSummativeRatingComponent.vue';
import ERKey from '../../components/er/ERKeyComponent.vue';
import Footnotes from '../../components/FootnotesComponent.vue';
import PageTitleColorBar from '../../components/PageTitleColorBar.vue';
// import MainEppprHeader from '../../components/MainEppprHeader.vue';

import DateFormatMixin from '../../mixins/date-format-mixins';

export default {
  components: {
    ERTeacherSGOScore,
    ERTeacherSPGScore,
    ERTeacherPracticeScore,
    ERSummativeRating,
    ERKey,
    Footnotes,
    PageTitleColorBar,
    // MainEppprHeader,

  },
  mixins: [DateFormatMixin],
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  mounted() {
    this.resizeScoreBox();
    window.addEventListener('resize', this.resizeScoreBox);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeScoreBox);
  },
  methods: {
    resizeScoreBox() {
      const parentDom = document.getElementById('scores');
      if (parentDom && parentDom !== null) {
        let box = parentDom.getElementsByClassName('sub-text');
        let height = 0;
        for (let i = 0; i < box.length; i += 1) {
          box[i].style.height = 'auto';
          const el = box[i];
          const elHeight = el.offsetHeight;
          if (elHeight > height) {
            height = elHeight;
          }
        }
        for (let i = 0; i < box.length; i += 1) {
          box[i].style.height = `${height}px`;
        }

        box = parentDom.getElementsByClassName('headline');
        height = 0;
        for (let i = 0; i < box.length; i += 1) {
          box[i].style.height = 'auto';
          const el = box[i];
          const elHeight = el.offsetHeight;
          if (elHeight > height) {
            height = elHeight;
          }
        }
        for (let i = 0; i < box.length; i += 1) {
          box[i].style.height = `${height}px`;
        }
      }
    },
  },
  data() {
    return {
      notes: [
        { id: 13, note: ' Note: Teachers classified as “NE (Not Evaluated)” are not included in the charts but are included in Counts. <br/><br/>Note: Data has been suppressed where the number of teachers is fewer than 10 to ensure the privacy of individual teachers.' },
      ],
    };
  },
  computed: {
    showOverlay() {
      if (this.year === 2021 || this.year === '2021') {
        return true;
      } if (this.year === 2022 || this.year === '2022') {
        return true;
      }
      return false;
    },
    overlayMessage() {
      if (this.year === 2022 || this.year === '2022') {
        return 'Summative scores in the 2020-2021 school year were not based on student growth due to the circumstances related to the COVID-19 public health emergency. Therefore, this data is not being included in this report.';
      }
      if (this.year === 2021 || this.year === '2021') {
        return 'The majority of teachers in the state did not receive a summative rating for the 2019-2020 school year due to the circumstances related to the COVID-19 public health emergency. Therefore, this data is not being included in this report.';
      }
      return '';
    },
    evaluationTitle() {
      if (this.year >= 2020) {
        return 'Evaluation Results and Impact Data';
      }
      return 'Evaluation Results';
    },
    items() {
      return [
        {
          icon: 'fas fa-clipboard-list er',
          colorbar: 'er-color-bar',
          color: 'er title-size',
          title: this.evaluationTitle,
          to: '/evaluation-results',
          subtitle: `Evaluation data for certified program completers from the ${this.prior2SchoolYearShort(this.year)} school year employed in the ${this.priorSchoolYearShort(this.year)} school year.`,
        },
      ];
    },
  },
};
</script>

<style scoped>
.content-container {
  position: relative;
}
</style>
<style>
.overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}
div.overlay-message {
  position: relative;
  align: middle;
  height: 50%;
}
span.overlay-message {
    position: absolute;
    top: 50%;
    left: 15%;
    width: 70%;
    background-color: white;
    padding: 15px 10px;
    border-radius: 10px;}
@media screen {
  .er{
        color: #006100;
    }
    .container {
        flex: 0;
        padding: 0;
    }
    .headline{
        color: #006100;
        font-size: 22px;
    }
    a{
        text-decoration: none;
    }
    .Highly{
        background: #303030;
        border: 1px solid #ffffff;
        color: #ffffff;
        fill: #303030;
        stroke: #ffffff;
    }
    .Highly-text, .Effective-text, .Partially-text {
        fill: #ffffff;
    }
    .Ineffective-text, .NE-text {
        fill: #000000;
    }
    .Effective{
        background: #575757;
        border: 1px solid #ffffff;
        color: #ffffff;
        fill: #575757;
        stroke: #ffffff;
    }
    .Partially{
        background: #A9A9A9;
        border: 1px solid #ffffff;
        color: #ffffff;
        fill: #A9A9A9;
        stroke: #ffffff;
    }
    .Ineffective{
        background: #DCDCDC;
        border: 1px solid #000000;
        fill: #DCDCDC;
        stroke: #000000;
    }
    .NE{
        background: #ffffff;
        border: 1px solid #696969;
        fill: #ffffff;
        stroke: #696969;
    }
    .sm{
        padding: 10px;
        margin: 5px;
    }
    /* .sub-text{
        margin: 16px;
    } */
    .count-title{
        font-weight: bold;
    }
    .rating-count-wrapper{
        padding: 15px;
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .bubble-chart-wrapper{
        /*height: 290px;*/
        width: 100%;
    }
    .spg-count-wrapper{
        float: right;
        margin-right: 50px;
        margin-top: 80px;
    }
    #display-0{
        display: none;
      }
      .bubble-chart-wrapper{
            height: 290px;
            width: 290%;
            margin-left: auto;
            margin-right: auto;
        }
        .border {
          min-height: 555px;
        }
}
</style>
