<template>
  <div class="er-comp">
    <v-container fluid>
      <v-row dense no-gutters>
        <v-col cols="12">
      <v-card tile class="rating-wrapper border mx-auto">
          <v-card-title class="">Teacher SGP Score</v-card-title>
          <v-card-text>
            <div class="sub-text">Median Student Growth Percentile (mSGP) scores
             are one of the multiple measures of student achievement for
              qualifying teachers of 4th-8th-grade Language Arts and 4th-7th-grade Math.</div>
              <div class="rating-count-wrapper">
              <div class="sm Highly">
                Highly Effective: {{ sgpScore.count[0] }}&nbsp;&nbsp;
                <span class="sr-only">,{{ sgpScore.percent[0] }}%</span></div>
              <div class="sm Effective">
                Effective: {{ sgpScore.count[1] }}&nbsp;&nbsp;
                <span class="sr-only">,{{ sgpScore.percent[1] }}%</span></div>
              <div class="sm Partially">
                Partially Effective: {{ sgpScore.count[2] }}&nbsp;&nbsp;
                <span class="sr-only">,{{ sgpScore.percent[2] }}%</span></div>
              <div class="sm Ineffective">
                Ineffective: {{ sgpScore.count[3] }}&nbsp;&nbsp;
                <span class="sr-only">,{{ sgpScore.percent[3] }}%</span></div>
              <div class="sm NE">
                NE: {{ sgpScore.count[4] }} </div>
          </div>
          <div v-if="hasData">
            <bubble-chart aria-hidden="true" :percent="sgpScoreData" class="bubble-chart-wrapper">
            </bubble-chart>
            <table class="sr-only">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Count</th>
                  <th>Percent</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(r, i) in sgpScoreData.labels" :key="r">
                  <td>{{ r }}</td>
                  <td>{{ sgpScoreData.count[i] }}</td>
                  <td>{{ sgpScoreData.percent[i] }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else style="text-align:center;width: 100%;">
            No Data to Display at this Time.</div>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
import BubbleChart from '../BubbleChart.vue';

export default {
  name: 'ERTeacherSPGScoreComponent',
  components: { BubbleChart },
  props: { provider: {}, program: {}, year: {} },
  computed: {
    sgpScore() {
      return this.$store.getters.sgpScore(this.provider, this.program, this.year);
    },
    sgpScoreData() {
      const r = this.sgpScore;
      const newSgpScore = { labels: r.labels };
      newSgpScore.percent = r.percent.map((v) => ((typeof v === 'number') ? v : 0));
      newSgpScore.count = r.count.map((v) => ((typeof v === 'number') ? v : 0));
      return newSgpScore;
    },
    hasData() {
      return this.sgpScoreData.count.filter((v) => v !== 0).length > 0;
    },
  },
};
</script>

<style scoped>
  .border{
      border: solid 1px;
      margin: 10px;
  }
  .rating-wrapper{
    /* height:100%; */
  }
  .sub-text{
    line-height: 1.2;
  }
  .v-card__title{
    font-size: 15px;
    padding-top: 5px;
  }
  .v-card__text{
    padding: 2px;
  }

</style>
