<template>
  <v-flex>
      <v-card tile flat max-width="100%" v-if="genders !== null">
          <v-card-title class="headline cd-headline">Completers by Gender:</v-card-title>
          <v-card-text>
              <div class="gender">
                  <span v-for="f in female" title="Female"
                   :key="f.Subgroup" :class="f.Subgroup"
                    :style="{width: width(f.Percentage) + '%' }">
                     {{ percentOrBlank(f.Percentage) }}%</span>
                  <span v-for="m in male" title="Male" :key="m.Subgroup"
                   :class="m.Subgroup" :style="{width: width(m.Percentage) + '%' }">
                    {{ percentOrBlank(m.Percentage) }}%</span>
              </div>
              <div class="not-gender">
                  <span v-for="n in not" title="Gender Not Reported" :key="n.Subgroup"
                   :class="n.Subgroup" :style="{width: width(n.Percentage) + '%' }">
                    {{ percentOrBlank(n.Percentage) }}%</span>
              </div>
          </v-card-text>
      </v-card>
    <v-simple-table class="d-none d-sr-only">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Gender Sub Group</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f in female" :key="f.Subgroup">
            <td>Female</td>
            <td>{{percentOrBlank(f.Percentage)}}%</td>
          </tr>
          <tr v-for="m in male" :key="m.Subgroup">
            <td>Male</td>
            <td>{{percentOrBlank(m.Percentage)}}%</td>
          </tr>
          <tr v-for="n in not" :key="n.Subgroup">
            <td>Gender Not Reported</td>
            <td>{{percentOrBlank(n.Percentage)}}%</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'CDGender',
  mixins: [NumberFormatMixin],
  props: { provider: {}, program: {}, year: {} },
  mounted() {
    console.log('CDGender Mounted');
  },
  computed: {
    genders() {
      return this.$store.getters.gender(this.provider, this.program, this.year);
    },
    male() {
      const m = this.genders;
      let maleData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return maleData = m.filter((m) => m.Subgroup === 'M');
    },
    female() {
      const f = this.genders;
      let femaleData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return femaleData = f.filter((f) => f.Subgroup === 'F');
    },
    not() {
      const n = this.genders;
      let notData;
      // eslint-disable-next-line no-return-assign, no-unused-vars, no-shadow
      return notData = n.filter((n) => n.Subgroup === 'Gender Not Reported');
    },
  },
  methods: {
    width(Percentage) {
      return Percentage;
    },
  },
  data() {
    return {
      styleObject: {
        width: '%',
      },
    };
  },
};
</script>

<style scoped>
  .small {
      max-width: 300px;
      margin:  15px auto;
  }
  .chart-title{
      font-size: 1.4em;

  }
  .centered{
      margin-left: 40%;
      float: left;
      height: 100px;
  }
  .gender{
      height: 40px;
      width: 75%;
      float: left;
  }
  .not-gender{
      float: left;
      width: 23%;
      margin-top: 6px;
      margin-left: 10px;
  }
  .F{
      padding: 15px 0;
      background: #696969;
      color: white;
      font-weight: bold;
      border: 1px solid #696969;
      float: left;
      text-align: center;
      max-width: 70%;
      min-width: 30%;
  }
  .F:before{
      content: "Female";
      color: white;
      font-weight: bold;
  }
  .M{
      padding: 15px 0;
      background: #ffffff;
      border: 1px solid #696969;
      margin: 0;
      font-weight: bold;
      float: left;
      text-align: center;
      max-width: 70%;
      min-width: 30%;
  }
  .M:before{
      content: "Male";
  }
  .Not:before{
      content: "Unreported/Unknown";
  }
  .unknown{
      font-weight: bold;
  }
  .headline{
      color: #114d72;
  }
</style>
<style>
</style>
