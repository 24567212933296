import { render, staticRenderFns } from "./ERTeacherSGOScoreComponent.vue?vue&type=template&id=4ead8301&scoped=true&"
import script from "./ERTeacherSGOScoreComponent.vue?vue&type=script&lang=js&"
export * from "./ERTeacherSGOScoreComponent.vue?vue&type=script&lang=js&"
import style0 from "./ERTeacherSGOScoreComponent.vue?vue&type=style&index=0&id=4ead8301&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ead8301",
  null
  
)

export default component.exports